import './App.css';
import { NavButton } from './components/navbutton/NavButton.js';

const address_en = 'https://withjoy.com/patricia-and-martin'
const address_cz = 'https://withjoy.com/patricia-and-martin-cz'


function App() {
  return (
    <div className='background'>
      <div className="app">
        <div className='main-content'>
          <h1>PATRICIA<span className='ampersand'>&</span>MARTIN</h1>
          <div className='location'>Chateau Ratměřice, Czech Republic</div>
          <h2>26. 06. 23</h2>
          <div className='navigation-layout'>
            <NavButton title="Česky" address={address_cz} />
            <NavButton title="English" address={address_en} />
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;